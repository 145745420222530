import "./testimonials.scss";

export default function Testimonials() {
  const data = [
    {
      id: 1,
      name: "Christian Uzieyi",
      title: "Senior Developer",
      img:
        "https://media-exp1.licdn.com/dms/image/C4D22AQEp0so_67WBEA/feedshare-shrink_800/0/1660314210545?e=1664409600&v=beta&t=00tmThpwzDlgVJqgJD8KZIeTfpGW9rCmIxuWOZBYT_s",
      icon: "assets/link.png",
      desc:
        "Happy worked for my web services company for nearly 2 years, and he was routinely throughout that time the most creative and capable web developers we had. He had a fantastic eye for unique designs, and was able to implement some of the best strategies our company had..",
    },
    {
      id: 2,
      name: "Faotu Happy",
      title: "Senior Frontend Developer",
      img:
        "https://images.pexels.com/photos/428321/pexels-photo-428321.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      icon: "assets/youtube.png",
      desc:
        " A huge part of Happy’s job with us was coordinating interdepartmentally to facilitate the completion of company-wide goals through web development. He was always thoughtful and considerate, an excellent team player who can also work independently and do a fantastic job. Happy can seemingly do anything, so he’s very highly recommended.",
      featured: true,
    },
    {
      id: 3,
      name: "Martin Harold",
      title: "CEO of ALABABA",
      img:
        "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      icon: "assets/linkedin.png",
      desc:
        "Happy is a dedicated frontend dev with a kin eye of product design and builds user responsive web applications",
    },
  ];
  return (
    <div className="testimonials" id="testimonials">
      <h1>Testimonials</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img src="assets/remover.png" className="left" alt="" />
              <img
                className="user"
                src={d.img}
                alt=""
              />
              <img className="right" src={d.icon} alt="" />
            </div>
            <div className="center">
              {d.desc}
            </div>
            <div className="bottom">
              <h3>{d.name}</h3>
              <h4>{d.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}