import "./topbar.scss"
import {AiOutlineMail} from "react-icons/ai"
import {FiPhoneCall} from "react-icons/fi"




export default function Topbar({menuOpen, setMenuOpen}) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
        <div className="wrapper">
        <div className="left">
            <a href="#intro" className="logo">CodeGen.</a>
            <div className="itemContainer">
            <FiPhoneCall className="icon"/>
            <span className="text">+2348036283915</span>
            </div>
            <div className="itemContainer">
            <AiOutlineMail className="icon"/>
            <span className="text">faotuhappy@gmail.com</span>
            </div>
        </div>
        <div className="right">
           <div className="hamburger" onClick={() =>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>

           </div>
        </div>
        </div>
    </div>
  )
}
