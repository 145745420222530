import "./menu.scss";

export const Menu = ({menuOpen, setMenuOpen}) => {
  return (
    <div className={"menu " + (menuOpen && "active")}>
        <ul>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#intro">Home</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#portfolio">Portfolio</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#work">Work</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#testimonials">Testimonials</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#contact">contact</a>
            </li>
        </ul>
    </div>
  )
}
