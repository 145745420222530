import { useState } from "react";
import "./work.scss";

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlider, setCurrentSlider] = useState(0)
    const data = [
      {
        id: "1",
        icon: "assets/pic2.png",
        title: "Web design",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore, veniam voluptas esse ipsam ab mollitia nostrum sed?",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShmZ5h_pb-NsdMUcnry2ub9QdOnEqjPMa8Eg&usqp=CAU", 
      },
      {
        id: "2",
        icon: "assets/pic2.png",
        title: "Mobile Appliaction",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore, veniam voluptas esse ipsam ab mollitia nostrum sed?",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcOAPMIig-EBPApPsJKL9qfl5jpEgWqkg-0g&usqp=CAU",
      },
      {
        id: "3",
        icon: "assets/arrow1.png",
        title: "Branding",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore, veniam voluptas esse ipsam ab mollitia nostrum sed?",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5DcTSgCwRgtcUtUJyckBygQlujxUlt7LYQA&usqp=CAU",
      },
  
    ];
  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };
  
  return (
    <div className="works" id="works">
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((d) => (
          <div className="container">
            <div className="item">
              <div className="left">
                <div className="leftContainer">
                  <div className="imgContainer">
                    <img src={d.icon} alt="" />
                  </div>
                  <h2>{d.title}</h2>
                  <p>{d.desc}</p>
                  <span>Projects</span>
                </div>
              </div>
              <div className="right">
                <img
                  src="https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
                  alt=""
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <img
        src="assets/arrow1.png"
        className="arrow left"
        alt=""
        onClick={() => handleClick("left")}
      />
      <img
        src="assets/arrow1.png"
        className="arrow right"
        alt=""
        onClick={() => handleClick()}
      />
    </div>
  );
}
